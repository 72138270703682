import React, { useState, useEffect } from "react"
import axios  from 'axios'
import { 
   Box, 
   Text,
   Image,
   Button,
   Center,
   Input,
   InputLeftElement,
   InputGroup,
   Spinner,
   Link,
   NumberInput,
   NumberInputField,
   Accordion,
   AccordionItem,
   AccordionButton,
   AccordionPanel,
   AccordionIcon,
   Select,
   useToast,
   AspectRatio,
   Heading,
   Table,
   Thead,
   Tbody,
   Tfoot,
   Tr,
   Th,
   Td,
   TableCaption,
} from "@chakra-ui/react"


// markup
const IndexPage = () => {
   const [machineName,setmachineName] = useState("");
   const [getmachines,setgetmachines] = useState([]);

   const getHollsfromMachineName = (machineName) => {
      var type="getHollsfromMachineName";
      // let postdata = Object.assign({type:type}, {word:word});
      let postdata = {
         type:type, 
         machineName:machineName,
      };
      axios.post('https://newmachinetool-api.yt-search.net/', postdata)
         .then( (res) => {
            console.log(res.data);
            setgetmachines(res.data.result);
         })
         .catch( (error) => {
            console.log(error);
      });
   }

   useEffect(() => {
      
      var type="test";
      let postdata = Object.assign({type:type});
      axios.post('https://newmachinetool-api.yt-search.net/', postdata)
         .then( (res) => {
            console.log(res.data);
         })
         .catch( (error) => {
            console.log(error);
      });

   }, []);

   return (
      <>

      <Heading m="1rem">New Machine Tool</Heading>
      <Input 
         m="1rem" 
         placeholder="機種名" 
         size="lg" 
         onChange={(e) => setmachineName(e.target.value)}
      />
      <Button 
         m="1rem" 
         colorScheme="blue"
         onClick={() =>getHollsfromMachineName(machineName)}
      >
         検索
      </Button>

      <Box>
         <Table variant="simple" m="1rem">
         <TableCaption>New Machine Tool</TableCaption>
            <Thead>
               <Tr>
                  <Th>店舗名</Th>
                  <Th>都道府県</Th>
                  <Th>貸率</Th>
                  <Th>機種名</Th>
                  <Th>台数</Th>
                  <Th>台数(数無)</Th>
                  <Th>増減</Th>
                  <Th>増減(数無)</Th>
                  <Th>変化日</Th>
               </Tr>
            </Thead>
            <Tbody>
            {
               React.Children.toArray(getmachines.map((val,index)=>{
                  return (
                     <Tr>
                        <Td>
                           <Box mb="0.2rem"><Link href={val.url} isExternal>{val.name}</Link></Box>
                           <Box fontSize="small"><Link href={"https://www.google.com/search?q=" + val.name + " site:http://www.d-deltanet.com/"} isExternal>サイトセブン</Link></Box>
                           <Box fontSize="small"><Link href={"https://www.google.com/search?q=" + val.name + " site:https://p-tora.com/"} isExternal>パチトラ</Link></Box>
                           <Box fontSize="small"><Link href={"https://www.google.com/search?q=" + val.name + " site:https://p-town.dmm.com/"} isExternal>DMM</Link></Box>
                        </Td>
                        <Td>{val.prefecture}</Td>
                        <Td>{val.kashi}</Td>
                        <Td>{val.machineName}</Td>
                        <Td>{val.daisuu}</Td>
                        <Td>{val.unkwounDaisuu}</Td>
                        <Td>{val.zougen}</Td>
                        <Td>{val.unkwounZougen}</Td>
                        <Td>{val.changedDate}</Td>
                     </Tr>
                  )
               }))
            }
            </Tbody>
         </Table>
      </Box>
      </>
   )
}

export default IndexPage
